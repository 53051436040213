import SearchLogo from "src/assets/Search";
import { useTable, useSortBy, useGlobalFilter, useAsyncDebounce, usePagination } from "react-table";
import clsx from "clsx";
import UpArrowIcon from "src/assets/UpArrow";
import ForwardRight from "src/assets/ForwardRight";
import ArrowRight from "src/assets/ArrowRight";
import Select from "react-select";
import SyncStatus from "src/components/SyncStatus";
import {
  loadInventoryTransactions,
  loadConfig,
  loadAccounts,
  loadjob,
  loadJob,
} from "src/redux/actions/shopify";
import customSelectTheme from "src/utils/selectTheme";
import EmptyTray from "src/assets/EmptyTray";
import CloseIcon from "src/assets/Close";
import {
  migrateShopifyData,
  migrateShopifyDataForInventoryTransactions,
  updateShopifyInventoryTransactionsValue,
  migrateShopifyInventoryTransactionsToDeskera,
} from "src/api";
import { addToast } from "src/redux/actions/toasts";
import ArrowClockwise from "src/assets/ArrowClockwise";
import { getDeskeraTenantDetails } from "src/api";
import Moment, { suppressDeprecationWarnings } from "moment";
import { startDateFormat } from "src/utils/Constants";
import { DKStatus, DKFilterPopup, DKButton, DKIcons } from "deskera-ui-library";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Alert from "src/components/Alert";
import { getQueryParams } from "src/utils/Utility";
import ShopifyStoreFilter from "src/components/Shopify/ShopifyStoreFilter";

function InventoryTransactions() {
  const inventoryTransactions = useSelector((state) => state.shopify.inventoryTransactions);
  const data = useMemo(
    () => (inventoryTransactions.data.content ? inventoryTransactions.data.content : []),
    [inventoryTransactions]
  );
  const fullData = useMemo(() => inventoryTransactions.data, [inventoryTransactions]);
  const dispatch = useDispatch();
  const [status, setStatus] = useState([]);
  const job = useSelector((state) => state.shopify.job);
  const [triggered, setTriggered] = useState(false);
  const [triggered1, setTriggered1] = useState(false);
  const [hover, setHover] = useState(false);
  const [value, setValue] = useState();
  // const [params, setParams] = useState({});
  // const [pageCount, setPageCount] = useState(0);
  // const [pageSize, setPageSize] = useState(10);
  // const [pageIndex, setPageIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  const syncInOutTransactions = useSelector(
    (state) => state.shopify.config.data.syncInOutTransactions
  );
  const [query, setQuery] = useState({});
  const accounts = useSelector((state) => state.shopify.accounts);

  const [updateRow, setUpdateRow] = useState();
  const [updatedAmount, setUpdatedAmount] = useState(0);
  const [syncStausFilter, setSyncStausFilter] = useState("");

  const columns = useMemo(
    () => [
      {
        Header: "Movement Type",
        accessor: "movementType",
      },
      {
        Header: "Product Id",
        accessor: "productId",
      },
      {
        Header: "Product Name",
        accessor: "productName",
      },
      {
        Header: "Transaction Type",
        accessor: "transactionType",
      },
      {
        Header: "Quantity",
        accessor: "quantity",
      },
      {
        Header: "Transaction Number",
        accessor: "transactionNo",
      },
      {
        Header: "Contact Name",
        accessor: "contactName",
      },
      {
        Header: "Rate/Value",
        accessor: "value",
      },
      {
        Header: "Source",
        accessor: "source",
      },
      {
        Header: "Shopify Store",
        accessor: "shopifyStore",
        Cell: ({ cell: { row } }) => {
          const account =
            row.original.shopUrl &&
            accounts?.data?.find((item) => item.id === row.original.shopUrl);
          return account?.name || "";
        },
        disableSortBy: true,
      },
      {
        Header: "Sync Status",
        accessor: "syncStatus",
        Cell: ({ cell: { row } }) => {
          return (
            <SyncStatus
              status={row.original.syncStatus}
              reason={row.original.reason}
              id={row.original.cartCustomerId}
            />
          );
        },
        disableGlobalFilter: true,
      },
      {
        Header: "Action",
        accessor: "totalAmount",
        className: "update-amount-col",
        disableGlobalFilter: true,
        Cell: (props) =>
          props?.row?.original?.syncStatus === "PENDING" &&
          props?.row?.original?.source === "Shopify" &&
          props?.row?.original?.movementType === "IN" &&
          props?.row?.original?.value === 0 && (
            <button
              className="btn btn-outline-success"
              style={{ lineHeight: 1 }}
              onClick={() => handleShow(props)}
            >
              Update Value
            </button>
          ),
      },
    ],
    [accounts]
  );

  const handleShow = (cell) => {
    setUpdateRow(cell?.row?.original);
  };

  async function updateInventoryTransactions() {
    if (!updateRow || !updateRow.id || updatedAmount === 0) {
      return;
    }
    setTriggered(true);
    const requestBody = {
      totalAmount: updatedAmount,
    };
    try {
      await updateShopifyInventoryTransactionsValue(updateRow.id, requestBody);
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Sync job completed",
        })
      );
      setTriggered(false);
      setUpdateRow();
      setUpdatedAmount(0);
      dispatch(loadInventoryTransactions(pageSize, pageIndex, syncStausFilter));
    } catch (err) {
      setTriggered(false);
    }
  }

  function inventoryTransactionsAmoutUpdate() {
    return (
      <div className="d-flex">
        <div
          className="vw-100 vh-100 d-flex justify-content-center align-items-center"
          style={{
            zIndex: 99,
            position: "absolute",
            top: 0,
            left: 0,
            background: "rgba(0, 0, 0, 0.4)",
          }}
        >
          <div className="card dk-card dk-card-shadow d-flex" style={{ minWidth: "30%" }}>
            <div className="card-body d-flex flex-column" style={{ maxWidth: 550 }}>
              <div className="card-body d-flex flex-column">
                <div className="form-group">
                  <div className="text-muted mb-3" aria="label">
                    <b>Enter rate/value for Shopify inventory transaction</b>
                  </div>
                  <input
                    type="number"
                    className="form-control"
                    value={updatedAmount}
                    onChange={(e) => setUpdatedAmount(e.target.value)}
                  />
                </div>

                <div>
                  <span data-tip="React-tooltip" data-for={updateRow} className="text-muted">
                    *The rate/value of this transaction is zero and system can not create{" "}
                    {updateRow?.transactionType ? updateRow?.transactionType : "document"} with zero
                    rate/value. Please update the rate/value.
                  </span>
                </div>
              </div>
              <div className="d-flex flex-row-reverse mt-3">
                <button
                  className="btn dk-btn font-weight-bold text-success border border-success px-4"
                  onClick={(e) => {
                    updateInventoryTransactions();
                  }}
                  disabled={updatedAmount && Number(updatedAmount) > 0 && !triggered ? false : true}
                >
                  <span>Update</span>
                </button>
                <button
                  className="btn dk-btn mr-2 px-3 font-weight-bold text-muted border border-secondary"
                  onClick={(e) => {
                    setUpdateRow(null);
                    setUpdatedAmount(0);
                  }}
                  disabled={triggered}
                >
                  <span className="svg-icon svg-baseline mr-2 svg-disabled">
                    <CloseIcon />
                  </span>
                  <span>Cancel</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
      },
      pageCount: fullData.totalPages,
      manualPagination: true,
      autoResetPage: false,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const colummsLength = columns.length;
  const dataLength = data.length;
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  function handleChange(e) {
    e.preventDefault();
    setValue(e.target.value);
    onChange(e.target.value);
  }

  const pageSizeOptions = [
    {
      value: 10,
      label: "10",
    },
    {
      value: 20,
      label: "20",
    },
    {
      value: 30,
      label: "30",
    },
  ];

  const syncStatusOptions = [
    {
      value: "",
      label: "All",
    },
    {
      value: "PENDING",
      label: "Pending",
    },
    {
      value: "SUCCESSFUL",
      label: "Synced",
    },
  ];

  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      minWidth: "230px",
    }),
  };

  const statusOptions = [
    {
      value: "SUCCESSFUL",
      label: "Successful",
    },
    {
      value: "FAILED",
      label: "Failed",
    },
    {
      value: "PENDING",
      label: "Pending",
    },
  ];
  const updateSelectedStatus = (options) => {
    var selected = [];
    options.forEach((element) => {
      selected.push(element.value);
    });
    setStatus(selected);
  };

  useEffect(() => {
    dispatch(loadConfig());
    dispatch(loadInventoryTransactions());
    if (accounts?.data?.length === 0) {
      dispatch(loadAccounts());
    }
  }, []);

  useEffect(() => {
    setLoading(false);
  }, [fullData]);

  useEffect(() => {
    refreshData();
    console.log(pageIndex);
  }, [pageIndex, pageSize, query]);

  useEffect(() => {
    dispatch(loadJob());
  }, job);

  const config = useSelector((state) => state.shopify.config);

  const refreshData = () => {
    if (!triggered) {
      setTriggered(true);
      setPageSize(pageSize);
      setLoading(true);

      const queryParams = getQueryParams(query);

      dispatch(loadInventoryTransactions(pageSize, pageIndex, syncStausFilter, queryParams));
      setTriggered(false);
    }
  };

  async function handleSyncSubmit() {
    setTriggered(true);
    var bbd;
    try {
      const tenantDetails = await getDeskeraTenantDetails();
      bbd = tenantDetails.bookBeginningStartDate;
    } catch (err) {
      setTriggered(false);
      return;
    }

    try {
      const body = { startDate: Moment(bbd).format(startDateFormat) };
      const resp = await migrateShopifyDataForInventoryTransactions(body);
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Sync job started",
        })
      );
    } catch (err) {
      dispatch(
        addToast({
          type: "danger",
          title: "Failure",
          message: err.response.data.message,
        })
      );
      // }
    }
    setTriggered(false);
  }

  async function handleShopifyInvSyncSubmit() {
    setTriggered1(true);
    var bbd;
    try {
      const tenantDetails = await getDeskeraTenantDetails();
      bbd = tenantDetails.bookBeginningStartDate;
    } catch (err) {
      setTriggered1(false);
      return;
    }

    try {
      const body = { startDate: Moment(bbd).format(startDateFormat) };
      const resp = await migrateShopifyInventoryTransactionsToDeskera(body);
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Sync job started",
        })
      );
    } catch (err) {
      dispatch(
        addToast({
          type: "danger",
          title: "Failure",
          message: err.response.data.message,
        })
      );
    }
    setTriggered1(false);
  }

  function Warning() {
    return <div className="bg-chip-blue border-radius-l p-h-s ml-s p-v-xs">Syncing...</div>;
  }

  return (
    <div className="p-4">
      <h3 className="mb-3">Inventory Transactions</h3>
      {(!config.data || !config.data.syncEnabled) && (
        <Alert type="warning">
          Your synced inventory transactions will appear here. Please complete{" "}
          <Link to="/app/shopify" className="text-alert-warning">
            <u>Setup</u>
          </Link>{" "}
          before continuing.
        </Alert>
      )}
      <>
        <div className="d-flex flex-column mb-2 space-between">
          <div style={{ width: "auto" }}>
            <div className="d-flex flex-row align-items-center">
              <button
                className="btn border-radius-m p-v-s text-white bg-success"
                onClick={() => refreshData()}
                disabled={triggered}
                style={{ marginRight: "10px" }}
              >
                Refresh
              </button>
              {syncInOutTransactions && (
                <button
                  className="btn border-radius-m p-v-s text-white bg-success"
                  onClick={() => handleSyncSubmit()}
                  disabled={triggered && triggered1}
                  onMouseOver={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                >
                  <span className="svg-icon svg-baseline mr-2 svg-white">
                    {triggered ? (
                      <div class="spinner-border text-white spinner-border-sm" role="status"></div>
                    ) : (
                      <></>
                    )}
                  </span>
                  <span>Sync Deskera To Shopify</span>
                </button>
              )}
              <button
                className="btn border-radius-m p-v-s text-white bg-success"
                onClick={() => handleShopifyInvSyncSubmit()}
                disabled={triggered1 && triggered}
                onMouseOver={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                style={{ marginLeft: "10px" }}
              >
                <span className="svg-icon svg-baseline mr-2 svg-white">
                  {triggered1 ? (
                    <div class="spinner-border text-white spinner-border-sm" role="status"></div>
                  ) : (
                    <></>
                  )}
                </span>
                <span>Sync Shopify To Deskera</span>
              </button>
            </div>
          </div>
          <div style={{ verticalAlign: "center", marginTop: "7px" }}>
            {(triggered || triggered1) && hover && Warning()}
          </div>
          <div style={{ marginLeft: "-4px" }}>
            <form className="form-inline" style={{gap:"2px"}}>
              {/* <span className="mr-2">Show by status:</span> */}
              <Select
                className="filter-selector ml-1 mr-1"
                options={syncStatusOptions}
                value={syncStatusOptions.filter((option) => option.value === syncStausFilter)}
                menuPlacement="auto"
                id="inventory-transaction-id"
                onChange={(e) => {
                  if (syncStausFilter !== e.value) {
                    setSyncStausFilter(e.value);
                    pageIndex > 0
                      ? gotoPage(0)
                      : dispatch(loadInventoryTransactions(pageSize, 0, e.value));
                  }
                }}
                theme={customSelectTheme}
                components={{
                  IndicatorSeparator: () => null,
                }}
                isSearchable={false}
              />
              <ShopifyStoreFilter
                accounts={accounts}
                customSelectTheme={customSelectTheme}
                customStyles={customStyles}
                query={query}
                setQuery={setQuery}
                />
              <Select
                placeholder="Filter By Sync Status"
                className="mr-2 ml-1"
                styles={customStyles}
                options={statusOptions}
                // value={statusOptions.filter((option) => option.value === status)}
                menuPlacement="auto"
                onChange={(e) => {
                  updateSelectedStatus(e);
                  console.log("status value  : ", status);
                  console.log("select value  : ", e);
                }}
                theme={customSelectTheme}
                components={{
                  IndicatorSeparator: () => null,
                }}
                isSearchable={false}
                isMulti={true}
              />
              {/* <div className="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text bg-light">
                  <span className="svg-icon svg-disabled">
                    <SearchLogo />
                  </span>
                </div>
              </div>
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                onChange={handleChange}
                value={value}
              />
            </div> */}
            </form>
          </div>
        </div>
        {updateRow && inventoryTransactionsAmoutUpdate()}
        <div className="card" style={{ overflowX: "auto" }}>
          <table className="table m-0 dk-table-hover dk-table" {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps([
                        { className: column.className },
                        column.getSortByToggleProps(),
                      ])}
                      style={{ whiteSpace: "nowrap" }}
                    >
                      <span className="fs-r text-align-left fw-m fs-m text-gray cursor-hand">
                        {column.render("Header")}
                      </span>
                      <span
                        className={clsx(
                          "svg-icon svg-disabled svg-baseline",
                          !column.isSorted && "invisible",
                          column.isSorted && column.isSortedDesc && "svg-flipped"
                        )}
                      >
                        <UpArrowIcon />
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {!loading && dataLength === 0 && (
                <tr>
                  <td colSpan={colummsLength}>
                    <div className="d-flex flex-column align-items-center">
                      <span className="mt-3 svg-disabled" style={{ width: "50px", height: "50px" }}>
                        <EmptyTray />
                      </span>
                      <span className="mt-2 text-muted">No records were found.</span>
                    </div>
                  </td>
                </tr>
              )}
              {!loading &&
                page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td
                          {...cell.getCellProps([{ className: cell.column.className }])}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          {cell.render("Cell")}
                        </td>
                      ))}
                    </tr>
                  );
                })}
            </tbody>
            {loading && (
              <tr>
                <td colSpan={colummsLength}>
                  <div className="d-flex flex-column align-items-center">
                    <span className="mt-2 text-muted">Loading...</span>
                  </div>
                </td>
              </tr>
            )}
            {/* {loading && <label className="d-flex justify-content-center">Loading...</label>} */}
          </table>
        </div>
        <div className="d-flex flex-row-reverse align-items-center mt-3">
          <div className="d-flex flex-row align-items-center">
            <span className="mr-2">Max rows per page:</span>
            <Select
              placeholder="Page"
              className="page-selector"
              options={pageSizeOptions}
              value={pageSizeOptions.filter((option) => option.value === pageSize)}
              menuPlacement="auto"
              onChange={(e) => {
                setPageSize(Number(e.value));
              }}
              theme={customSelectTheme}
              components={{
                IndicatorSeparator: () => null,
              }}
              isSearchable={false}
            />
          </div>
          <div className="d-flex flex-row align-items-center mr-2">
            <button className="btn mr-2" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              <span
                className={clsx(
                  "svg-icon svg-baseline svg-flipped",
                  canPreviousPage ? "svg-black" : "svg-disabled"
                )}
              >
                <ForwardRight />
              </span>
            </button>
            <button className="btn mr-2" onClick={() => previousPage()} disabled={!canPreviousPage}>
              <span
                className={clsx(
                  "svg-icon svg-baseline svg-flipped",
                  canPreviousPage ? "svg-black" : "svg-disabled"
                )}
              >
                <ArrowRight />
              </span>
            </button>
            <span className="mr-2">
              {pageIndex + 1} / {Math.max(pageOptions.length,1)}
            </span>
            <button className="btn mr-2" onClick={() => nextPage()} disabled={!canNextPage}>
              <span
                className={clsx(
                  "svg-icon svg-baseline",
                  canNextPage ? "svg-black" : "svg-disabled"
                )}
              >
                <ArrowRight />
              </span>
            </button>
            <button className="btn" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
              <span
                className={clsx(
                  "svg-icon svg-baseline",
                  canNextPage ? "svg-black" : "svg-disabled"
                )}
              >
                <ForwardRight />
              </span>
            </button>
          </div>
        </div>
      </>
    </div>
  );
}

export default InventoryTransactions;
