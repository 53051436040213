export const startDateFormat = 'DD-MM-YYYY';
export const yearFirstDateFormat = 'YYYY-MM-DD'; 
export const queryTemplate = "{key}={value}";
export const JAVASCRIPT_DATA_TYPE = Object.freeze({
    BOOLEAN : 'boolean',
    NUMBER : 'number',
    BIGINT : 'bigint',
    OBJECT : 'object',
    STRING : 'string',
    SYMBOL : 'symbol',
    UNDEFINED : 'undefined',
    FUNCTION : 'function',
    ARRAY : 'object'
})

export const CONSTANTS = {
  STORE: {
    SHOPIFY: "shopify",
    MAGENTO: "magento",
    BIGCOMMERCE: "bigcommerce",
    TALLY: "tally",
    BRIGHTPEARL: "brightpearl",
  },
  MODULE: {
    DESKERA_PRODUCT: "Deskera Product",
    PRODUCT: "Product",
    CUSTOMER: "Customer",
  },
};